@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}

.label {
  @apply font-bold text-gray-500 text-sm
}
.input {
  @apply w-full rounded outline-none p-3 mb-4 bg-gray-100
}
.submit-button {
  @apply w-full bg-primary-gradient p-2 rounded shadow hover:brightness-125
}
.loader-wrapper {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
    background: white;
  box-shadow: 0 0 0 0.5px #64E9FF, 0 0 8px 2px rgba(0, 0, 0, 0.2);
}

.loader {
  width: 100%;
  height: 70%;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: 
    radial-gradient(circle 11px at top, transparent 93%, #b0e5f3 94%) 0 20px,
    radial-gradient(circle 11px at top, transparent 93%, #5dc1e4 94%) 0 10px,
    radial-gradient(circle 11px at top, transparent 93%, #008cd9 94%) 0 0;
  background-size: 20px 100%;
  background-repeat: repeat-x;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
  50%  {background-position: 10px 15px, -10px 12px, 15px 8px}
  100% {background-position: 20px 20px, -20px 10px, 20px 0px}
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.logo {
  animation: pulseAnimation 2s infinite;
  display: inline-block;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
